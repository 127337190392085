.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
  transition: color 0.3s ease;
  
}

body {
  /**background-image: url(images/3d-futuristic.jpg);*/
  background-color: #000000;
  background-position: center;
  background-size: cover;
}

.white-background {
  background-color: #f5f5f5;
  min-height: 100vh; /* Asegura que ocupe toda la altura de la pantalla */
}


.prox-car{
  color: #ffffff;
  margin-top: 20px;
  text-align: center;
}

.card {
  width: 250px;
  margin: 10px;
  text-align: left;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 1px 20px rgba(100, 0, 0, 100);
  cursor: default;
  transition: all 400ms ease;
  justify-content: center;
  }

.card :hover{
  box-shadow: 0px 1px 20px rgba(100, 0, 0, 100);
  transform: translateY(-3%);
}

.card-img-top {
  height: 200px;
  width: 100%;
  
}

.main-footer {
  background-color: rgb(0, 0, 0);
  color: #fff;
  padding: 20px 0;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  box-shadow: 0px 0px 2px 3px rgb(240, 36, 36);  
}

.footer-bottom {
  text-align: center;
  background-color: red;
}

/**/

.top-bar {
  background-color: #ff0000;
  color: #fff;
}

.top-bar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.top-bar span, ul {
  font-size: 0.9rem;
  display: flex;
}

.main-footer {
  justify-content: space-between;
}

.footer-redes {
  font-size: 1.8rem;
}

.btn-redes {
  font-size: 1.3rem;
  margin-top: 5px;
}

.image-carousel {
  width: 100%;
  margin-top: 20px;
}

.image-carousel img {
  height: 300px;
  display: block;
  margin: auto;
  margin-top: 10px;
  box-shadow: 0px 1px 20px rgba(100, 0, 0, 100);
}

.image-banner img {
  max-height: 400px;
  width: 100%;
  overflow: hidden;
  display: block;
  margin: auto;
  margin-top: 10px;
  box-shadow: 0px 1px 20px rgba(100, 0, 0, 100);
}

/*table style*/
.table{
  color: #ffffff;
  background-color: #0f0f0f;
  border-radius: 5px;
}

.row-sep{
  background-color: #000000;
}
.container-table{
  color: white;
  justify-content: 'center';
  align-items: 'center';
  padding: '10px'
}


/*modal*/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.modal-content {
  background: rgb(0, 0, 0);
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #ffffff;
  padding: 5px;
  border-radius: 8px;
  width: 60%;
  max-width: 1080px;
  height: auto;
  max-height: 90%;
  box-shadow: 0px 1px 20px rgba(100, 0, 0, 100);
  text-align: center;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-body {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-image {
  width: 30%;
  height: auto;
  object-fit: cover;
}

/* Responsive*/
@media (min-width: 768px) {
  .modal-content {
    width: 60%; /* Ajuste de ancho para pantallas medianas */
    height: auto;
    max-height: 80%; /* Ajuste de alto para pantallas medianas */
  }
}

@media (min-width: 1024px) {
  .modal-content {
    width: 40%; /* Ajuste de ancho para pantallas grandes */
    height: auto;
    max-height: 900%; /* Ajuste de alto para pantallas grandes */
  }
}

/*form styles*/
/* Formulario.css */

.container-gral {         /*aqui tengo que ver el contenedor xp me cambia la pagina tambien*/
  background: #ffffff;
  margin-top: 20px;
  border-radius: 10PX;
  box-shadow: 3PX 2PX 2PX #ccc;
  padding: 20px;
  margin: 5%;
  color: #0e2f9c;
}

.container-form{
  background: #ebe8e8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 1px 3px;
}

.cont-form label {
  display: block;
  max-width: 100%;
  margin-top: 2%;
  margin-bottom: 3px;
  color: #333333;
  text-align: left;
  
}

.cont-form input, select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 1px 3px;
}

button {
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 15px;
}

button:hover {
  background-color: #e03838;
}

.image-form{
  width: 100%;
  max-height: 250px;
  margin-bottom: 20px;
  border-radius: 4px;

}

.filter-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.filter-group {
  flex: 1;
}

@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
}

@media screen and (max-width: 768px) {
  .table th, .table td {
    padding: 2px;
    font-size: 10px;
  }
}
